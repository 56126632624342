import { useEffect } from "react";
import IndexLayout from "../layouts";
import { navigate } from "gatsby";

const ProactiveSupportPage = () => {
  useEffect(() => {
    navigate("/education");
  }, []);

  return <IndexLayout></IndexLayout>;
};

export default ProactiveSupportPage;
